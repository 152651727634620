import React from "react"
import ReactCompareImage from "react-compare-image"
import RichTextRenderer from "../../RichTextRenderer"

const Details = ({
  data: {
    detailsSectionTitle,
    detailsSectionDescription,
    showcaseBeforeImage,
    showcaseAfterImage,
    showcaseTitle,
    showcaseSubtitle,
    showcaseDetails,
  },
}) => {
  return (
    <>
      <div className="max-w-6xl mx-auto">
        <div className="px-4 my-6 md:my-12 md:px-8 xl:px-0">
          <h2 className="text-2xl font-bold text-white md:text-3xl siteFont">
            {detailsSectionTitle}
          </h2>
          <div className="my-3 md:w-3/4 Gray md:text-lg">
            {RichTextRenderer(detailsSectionDescription.json)}
          </div>
        </div>
      </div>
    </>
  )
}

export default Details

import React, { useState } from "react"

const Services = ({ data }) => {
  const [open, setOpen] = useState(false)
  const [seletectedItem, setSelectedItem] = useState(0)

  const toggle = (index = 0) => {
    setOpen(true)
    setSelectedItem(index)
  }
  return (
    <div onClick={() => open && setOpen(false)}>
      <div className="max-w-6xl mx-auto text-white font-bold">
        <hr className="my-6 md:my-16 xl:mx-auto" />
        <div className="px-4 md:px-8 xl:px-0">
          <h2 className="uppercase text-3xl md:text-4xl text-center mb-10 font-bold siteFont">
            what can we do?
          </h2>
          <div className="relative">
            <div
              className={`openPopup ${
                open ? "block" : "hidden"
              } h-full md:h-full absolute p-4 rounded-lg top-0 bottom-0 right-0 left-0 bg-secondary flex flex-col`}
              onClick={e => e.stopPropagation()}
            >
              <div
                className="text-2xl font-semibold cursor-pointer absolute top-1/1 md:top-1/50 right-1/30 md:right-1/50"
                onClick={e => {
                  e.stopPropagation()
                  setOpen(false)
                }}
              >
                X
              </div>

              <div className="flex h-full flex-col md:flex-row justify-end lg:justify-between py-6 items-end lg:items-center overflow-hidden">
                <div className="w-3/5 md:w-1/3 h-auto mx-auto">
                  <img
                    src={data[seletectedItem].image.file.url}
                    alt={data[seletectedItem].image.title}
                    className="w-11/12"
                  />
                </div>
                <div className="w-full h-full md:w-1/2 text-white flex flex-col items-start justify-center">
                  <h1 className="w-11/12 mb-4 md:pt-0 text-white font-bold text-2xl sm:text-2xl lg:text-3xl siteFont">
                    {data[seletectedItem].title}
                  </h1>
                  <div className="font-light text-md">
                    {data[seletectedItem].description.description}
                  </div>
                </div>
              </div>
            </div>

            <div className="grid grid-cols-2 md:grid-cols-3 gap-4 lg:gap-6">
              {data.map((val, index) => {
                return (
                  <div
                    key={val.id}
                    className="servicesPopup rounded-lg cursor-pointer"
                    onClick={() => toggle(index)}
                  >
                    <div className="row-span-1 col-span-1 text-center md:p-8 py-6">
                      <div className="w-16 xl:w-20 mx-auto">
                        <img src={val.image.file.url} alt={val.image.title} />
                      </div>
                      <h3 className="text-lg md:text-2xl siteFont">
                        {val.title}
                      </h3>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
        <hr className="my-8 md:my-16 xl:mx-auto" />
      </div>
    </div>
  )
}

export default Services

import React from "react"
import ReactCompareImage from "react-compare-image"
import RichTextRenderer from "../../RichTextRenderer"

const Comparision = ({
  data: {
    showcaseBeforeImage,
    showcaseAfterImage,
    showcaseTitle,
    showcaseSubtitle,
    showcaseDetails,
  },
}) => {
  return (
    <div className="bg-white detailsSection">
      <div className="max-w-6xl px-4 mx-auto md:px-8 xl:px-0">
        <div className="w-11/12 py-12 mx-auto overflow-hidden rounded-lg md:w-3/5">
          <ReactCompareImage
            leftImage={showcaseBeforeImage.fluid.src}
            rightImage={showcaseAfterImage.fluid.src}
          />
        </div>
        <div className="pb-8 mx-auto text-center lg:w-5/6 xl:w-11/12 md:pb-16">
          <h1 className="mb-4 font-bold siteFont">{showcaseTitle}</h1>
          <h2 className="mb-4 text-xl font-bold md:text-3xl siteFont">
            {showcaseSubtitle.showcaseSubtitle}
          </h2>
          <div className="mt-8 mb-4 text-xl md:text-2xl darkGrey">
            {RichTextRenderer(showcaseDetails.json)}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Comparision

import React, { useContext } from "react"
import { graphql } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import Banner from "../../components/Services/WebsiteDevelopment/banner"
import Services from "../../components/Services/WebsiteDevelopment/services"
import Details from "../../components/Services/WebsiteDevelopment/details"
import Comparision from "../../components/Services/WebsiteDevelopment/comparision"
import Slider from "../../components/slider"
import ContactUs from "../../components/contactUs"
import Estimator from "../../components/PricingCalculator/estimator"

// Context API data
import { AppContext } from "../../store/AppContext"

const WebsiteMarketing = ({ data }) => {
  const globalData = useContext(AppContext)
  const {
    ogTitle,
    ogDescription,
    bannerTitle,
    bannerSubtitle,
    bannerDescription,
    bannerImage,
    services,
    detailsSectionTitle,
    detailsSectionDescription,
    showcaseBeforeImage,
    showcaseAfterImage,
    showcaseTitle,
    showcaseSubtitle,
    showcaseDetails,
  } = data.website

  const bannerData = {
    bannerTitle,
    bannerSubtitle,
    bannerDescription,
    bannerImage,
  }

  const detailData = {
    detailsSectionTitle,
    detailsSectionDescription,
  }
  const comparisionData = {
    showcaseBeforeImage,
    showcaseAfterImage,
    showcaseTitle,
    showcaseSubtitle,
    showcaseDetails,
  }

  return (
    <Layout>
      <SEO
        title={ogTitle || "Website Development"}
        description={ogDescription}
      />
      <Banner data={bannerData} customNav={globalData?.state?.customNav} />
      <Services data={services} />
      <Details data={detailData} />
      <Estimator singleService service="Website_Development" />
      <Comparision data={comparisionData} />
      <Slider blueBackground />
      <ContactUs />
    </Layout>
  )
}

export const query = graphql`
  query WebsitePageQuery {
    website: contentfulServicesWebsitePage {
      ogTitle
      ogDescription
      bannerTitle
      bannerSubtitle
      bannerDescription {
        json
      }
      bannerImage {
        title
        file {
          url
        }
      }
      services {
        id
        title
        description {
          description
        }
        image {
          title
          file {
            url
          }
        }
      }
      detailsSectionTitle
      detailsSectionDescription {
        json
      }
      showcaseBeforeImage {
        title
        fluid {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      showcaseAfterImage {
        title
        fluid {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      showcaseTitle
      showcaseSubtitle {
        showcaseSubtitle
      }
      showcaseDetails {
        json
      }
    }
  }
`

export default WebsiteMarketing
